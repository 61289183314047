@font-face {
  font-family: "Gilroy-Bold";
  src: local("Gilroy-Bold"),
    url("./assets/fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Eczar-Regular";
  src: local("Eczar-Regular"),
    url("./assets/fonts/Eczar-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Eczar-SemiBold";
  src: local("Eczar-SemiBold"),
    url("./assets/fonts/Eczar-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Eczar-Bold";
  src: local("Eczar-Bold"),
    url("./assets/fonts/Eczar-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  display: flex;
  font-family: "Gilroy-Bold", sans-serif !important;
  background-color: #17181c !important;
  overflow-x: hidden;
}

.app {
  display: flex;
  position: relative;
}
